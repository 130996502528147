<!--
* @Author: DY
* @Date: 2021年6月1日16:38:23
* @Description: '选择市场信息价(新) —— 已选/未选拆分成两个列表，优惠比例可编辑'
-->
<template>
    <div class="selectMarketInformationPrice_new" :class="{'seeScene': seeScene}" v-loading="loading">
        <div class="currencyTable">
            <div class="table-header" v-if="isselectRegion">
                <div class="el_left selectRegion">
                    <h4>区域选择： </h4>
                    <el-radio-group v-model="selectRegion" @change="selectArea">
                        <template v-for="(item, index) in regionData">
                            <el-radio :key="index" :label="item.info_price_regional_number">
                                {{ item.region_name }}
                            </el-radio>
                        </template>
                    </el-radio-group>
                </div>
            </div>
            <section :class="{'displayArea': isselectRegion}">
                <div class="table_l" v-if="seeScene == false">
                    <h3>可选附加型号</h3>
                    <currencyTable :table-data="selectedTableData" ref="selectedTable"></currencyTable>
                </div>
                <div class="btns" v-if="seeScene == false">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="添加"
                        placement="top"
                    >
                        <el-button
                            type="primary"
                            size="mini"
                            plain
                            @click="addSelection"
                        >
                            <i class="iconfont iconarrow2_right"></i>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="top"
                    >
                        <el-button
                            type="primary"
                            size="mini"
                            plain
                            @click="deleteSelection"
                        >
                            <i class="iconfont iconarrow2_left"></i>
                        </el-button>
                    </el-tooltip>
                </div>
                <div class="table_r">
                    <h3>已选附加型号</h3>
                    <currencyTable
                        ref="notSelectedTable"
                        :table-data="notSelectedTableData"
                        @proportionAdjustment="proportionAdjustment"
                    ></currencyTable>
                </div>
            </section>
        </div>
        <footer>
            <el-button
                type="primary"
                @click="confirmSelection"
                v-if="seeScene == false"
            >
                确认
            </el-button>
            <el-button @click="cancel">
                取消
            </el-button>
        </footer>
    </div>
</template>

<script>
import NP from 'number-precision';
NP.enableBoundaryChecking(false);
import currencyTable from '@/components/table/index.vue';

export default {
    name: 'select-market-information-price-new',
    components: {
        currencyTable,
    },
    props: {
        extr: {
            type: [Array, Object, String, Number],
        },
    },
    data() {
        return {
            loading: false,
            // 列表数据
            tableList: [],
            // 是否显示选择区域
            isselectRegion: false,
            // 区域数据
            regionData: [],
            // 选择区域
            selectRegion: '',
            // 回显数据
            echoData: [],
            // 回显过滤字段
            echoFilterFields: '',
            // 优惠比例
            discount_rate: 0,
            selectedTableData: {
                isShowNo: false,
                generalQuery: false,
                showCheckBox: true,
                isShowTablePage: false,
                headerData: [{
                    title: '产品名称',
                    field: 'info_price_name',
                }, {
                    title: '附加型号',
                    field: 'additional_model',
                }, {
                    title: '单位',
                    field: 'company',
                }, {
                    title: '含税单价',
                    field: 'included_tax_unit_price',
                }],
                listData: [],
            },
            notSelectedTableData: {
                isShowNo: false,
                generalQuery: false,
                showCheckBox: true,
                isShowTablePage: false,
                headerData: [{
                    title: '附加型号',
                    field: 'additional_model',
                }, {
                    title: '市场价格',
                    field: 'included_tax_unit_price',
                }, {
                    title: '优惠比例',
                    field: 'discount_rate',
                    isEdit: true,
                    editType: 'text',
                    isSee: this.extr.seeScene,
                    changeName: 'proportionAdjustment',
                }, {
                    title: '优惠后价格',
                    field: 'additional_model_price',
                }],
                listData: [],
            },
            // 查看场景
            seeScene: false,
        };
    },
    watch: {},
    computed: {},
    created() {
        if (this.extr) {
            this.selectRegion = this.extr.requestParameter.info_price_regional_number;
            if (this.extr.echoData) {
                this.echoData = this.extr.echoData;
                this.notSelectedTableData.listData = this.extr.echoData;
                this.echoFilterFields = this.extr.echoFilterFields;
            }
            if (this.extr.discount_rate) {
                this.discount_rate = this.extr.discount_rate;
            }
            if (this.extr.is_trans_regional) {
                this.isselectRegion = true;
                // 获取区域数据
                this.getRegionData();
            }
            if (this.extr.seeScene) {
                this.seeScene = true;
            }
            if (this.extr.requestUrl && !this.seeScene) {
                // 获取列表数据
                this.getTableData();
            }
        }
    },
    mounted() {},
    methods: {
        // 获取区域数据
        getRegionData() {
            this.$axios
                .get('/interfaceApi/sale/info_price/get_regional_list')
                .then(res => {
                    if (res) {
                        this.regionData = res;
                    } else {
                        this.regionData = [];
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择区域
        selectArea() {
            this.tableList = [];
            // 获取列表数据
            this.getTableData();
        },

        // 获取列表数据
        getTableData() {
            this.loading = true;
            const requestUrl = `/interfaceApi/sale/${this.extr.requestUrl}`;
            const requestParameters = {
                pageindex: 1,
                pagesize: 9999,
            };
            const requestData = Object.assign(this.extr.requestParameter, requestParameters);
            requestData.info_price_regional_number = this.selectRegion;
            this.$axios
                .post(requestUrl, requestData)
                .then(res => {
                    this.tableList = res.rows;
                    this.processingEchoData();
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 处理回显数据
        processingEchoData() {
            this.$nextTick(() => {
                let row = [];
                if (this.echoData.length > 0) {
                    row = [...this.tableList].filter(x => [...this.echoData].every(y => y[this.echoFilterFields] !== x[this.echoFilterFields]));
                } else {
                    row = this.tableList;
                }
                row.forEach(item => {
                    item.company = 'm³';
                });
                this.$refs.selectedTable.tableList = row;
            });
        },

        // 添加选择附加型号
        addSelection() {
            // 当前选中附加型号
            const currentlySelected = JSON.parse(JSON.stringify(this.$refs.selectedTable.tableSelectData));
            // 已选附加型号
            const selectedData = this.$refs.notSelectedTable.tableList;
            if (currentlySelected.length > 0) {
                selectedData.push(...currentlySelected);
                let newSelectedTableData = [];
                // eslint-disable-next-line max-len
                newSelectedTableData = [...this.tableList].filter(x => [...selectedData].every(y => y[this.echoFilterFields] !== x[this.echoFilterFields]));
                this.$refs.selectedTable.tableList = newSelectedTableData;
                this.$refs.selectedTable.tableSelectData = [];
                currentlySelected.forEach(k => {
                    k.discount_rate = this.discount_rate;
                    k.additional_model_price = NP.times(k.included_tax_unit_price, NP.minus(1, NP.divide(this.discount_rate || 0, 100)));
                });
            } else {
                this.$message({
                    message: '请先选择要添加的附加型号！',
                    type: 'warning',
                });
            }
        },
        // 删除选择附加型号
        deleteSelection() {
            // 当前选中附加型号
            const currentlySelected = JSON.parse(JSON.stringify(this.$refs.notSelectedTable.tableSelectData));
            // 已选附加型号
            let selectedData = this.$refs.notSelectedTable.tableList;
            if (currentlySelected.length > 0) {
                let newSelectedData = [];
                // eslint-disable-next-line max-len
                newSelectedData = [...selectedData].filter(x => [...currentlySelected].every(y => y[this.echoFilterFields] !== x[this.echoFilterFields]));
                selectedData = newSelectedData;
                this.$refs.notSelectedTable.tableList = selectedData;
                this.$refs.notSelectedTable.tableSelectData = [];

                let newSelectedTableData = [];
                // eslint-disable-next-line max-len
                newSelectedTableData = [...this.tableList].filter(x => [...selectedData].every(y => y[this.echoFilterFields] !== x[this.echoFilterFields]));
                this.$refs.selectedTable.tableList = newSelectedTableData;
            } else {
                this.$message({
                    message: '请先选择要删除的附加型号！',
                    type: 'warning',
                });
            }
        },

        // 调整比例
        proportionAdjustment(row, index) {
            const newData = JSON.parse(JSON.stringify(row));
            const formula = NP.times(row.included_tax_unit_price, NP.minus(1, NP.divide(row.discount_rate || 0, 100)));
            newData.additional_model_price = this.extr.numericalCalculation(formula);
            this.$set(this.$refs.notSelectedTable.tableList, index, newData);
        },

        // 确认选择
        confirmSelection() {
            const row = this.$refs.notSelectedTable.tableList;
            if (row.length < 1) {
                this.$message({
                    message: `请先选择${this.extr.tips}！`,
                    type: 'warning',
                });
            } else {
                this.extr.sureCallBack(row);
                this.$parent.hide();
            }
        },
        // 取消
        cancel() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus">
    .selectMarketInformationPrice_new
        height 100%
        &.seeScene
            .currencyTable
                section
                    .table_r
                        width 100%
        .currencyTable
            height calc(100% - 0.7rem)
            padding 0.14rem
            background #fff
            border none
            .table-header
                height 0.51rem
                .selectRegion
                    display flex
                    line-height 0.36rem
                    h4
                        font-size 0.14rem
                    .el-radio
                        line-height 0.36rem
                .btns
                    float left
                    .el-button
                        color #fff
                        background #2978ff
                        padding 0 0.2rem
                        border none
                        line-height 0.36rem
            section
                height 100%
                display flex
                &.displayArea
                    height calc(100% - 0.51rem)
                >div
                    height 100%
                .table_l,.table_r
                    width calc((100% - 0.72rem)/2)
                    .currencyTable
                        height calc(100% - 0.44rem)
                        padding 0
                        border-radius 0
                        .table-header
                            display none
                        .el-table
                            border-radius 0
                            height 100%
                    h3
                        height 0.44rem
                        font-size 0.16rem
                        line-height 0.44rem
                        text-align center
                        border-bottom none!important
                .table_l
                    h3
                        color #014C7C
                        background #CDDDED
                        border 1px solid #B8CFDB
                    .el-table
                        border 1px solid #B8CFDB
                .table_r
                    h3
                        color #5D4920
                        background #E8E6D8
                        border 1px solid #D5D0B9
                    .el-table
                        border 1px solid #D5D0B9
                        th
                            background #FCF9F6
                            color #823802
                .btns
                    width 0.72rem
                    display flex
                    flex-wrap wrap
                    align-content center
                    justify-content center
                    button
                        margin 0.1rem
                        padding 0
                        width 0.32rem
                        height 0.32rem
                        text-align center
                        line-height 0.32rem
        footer
            text-align center
            height 0.4rem
            .el-button
                width 2.4rem
                font-size 0.16rem
                line-height 0.4rem
                margin 0 0.2rem
                padding 0
</style>
